const spaces = {
  none: '0',
  xxSmall: '4px',
  xSmall: '8px',
  small: '12px',
  medium: '16px',
  large: '20px',
  xLarge: '24px',
  huge: '40px',
  xHuge: '60px',
  xxHuge: '80px',
} as const;

export { spaces };
