import React, { useEffect, useState } from 'react';
import { clients } from '../../data/clients.json';
import { Carousel, Padding } from '../../ui/components';
import { spaces } from '../../ui/tokens';
import { TempClientsWrapper } from './Clients.styles';

const Clients = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  const resetWidth = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    if (windowWidth === 0 && window) {
      console.log('a');
      resetWidth();
    }

    window.addEventListener('resize', resetWidth);
    return () => window.removeEventListener('resize', resetWidth);
  }, [windowWidth]);

  return (
    <TempClientsWrapper>
      <div
        style={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          padding: `${spaces.xHuge} 0`,
        }}
      >
        <Padding padding={`${spaces.xHuge} 0 0`}>
          {windowWidth < 1168 ? (
            <Carousel
              responsive={{
                desktop: {
                  breakpoint: { max: 9999, min: 1200 },
                  items: 7,
                },
                tablet: {
                  breakpoint: { max: 1200, min: 980 },
                  items: 4,
                },
                mobile: {
                  breakpoint: { max: 980, min: 768 },
                  items: 3,
                },
                minimalMobile: {
                  breakpoint: { max: 768, min: 0 },
                  items: 2,
                },
              }}
            >
              {clients.map((client) => {
                return (
                  <img
                    key={client.name}
                    src={client.url}
                    alt={client.alt}
                    title={client.name}
                  />
                );
              })}
            </Carousel>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {clients.map((client) => {
                return (
                  <img
                    key={client.name}
                    src={client.url}
                    alt={client.alt}
                    title={client.name}
                  />
                );
              })}
            </div>
          )}
        </Padding>
      </div>
    </TempClientsWrapper>
  );
};

export { Clients };
