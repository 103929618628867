const colors = {
  primary: '#E9F3FF',
  blue_100: '#6BA1E3',
  blue_600: '#1B3351',
  green_200: '#55CEC2',
  green_400: '#0AA494',
  white: '#FFFFFF',
  transparent: 'transparent',
  disabled: 'none',
  gray_200: '#888888',
};

export { colors };
