import styled from 'styled-components/macro';
import { ButtonStyle } from '../../ui/components/Button/Button.styles';

const TempBannerWrapper = styled.div`
  height: calc(100% - 159px);
  width: 100%;
  position: absolute;
  top: 0;
  padding: 90px 16px;

  @media screen and (max-width: 768px) {
    position: static;
    top: auto;
    height: auto;
    padding: 30px 0;

    & * {
      text-align: center;
    }
  }

  @media screen and (max-height: 947px) {
    position: static;
    top: auto;
    height: auto;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;

  & > * {
    margin-right: 16px;
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto;

    ${ButtonStyle} {
      width: 120px;
    }
  }
`;

const BannerLink = styled.a`
  display: block;
  width: 100%;
`;

const Picture = styled.picture`
  display: block;
  width: 100%;
  height: auto;
`;

export { TempBannerWrapper, ButtonsRow, BannerLink, Picture };
