import styled from 'styled-components/macro';

const TempClientsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  margin-top: -100px;
  width: 100%;

  @media screen and (max-width: 768px) {
    position: static;
    bottom: auto;
  }

  @media screen and (max-height: 847px) {
    position: static;
    bottom: auto;
  }
`;

export { TempClientsWrapper };
