import React from 'react';
import { VideoBase } from './Video.styles';

type VideoProps = {
  poster?: string;
  height?: string;
  width?: string;
  maxHeight?: string;
  maxWidth?: string;
  sources: Array<{
    src: string;
    type?: 'video/webm' | 'video/mp4';
  }>;
};

const Video = ({
  poster,
  sources,
  height = 'auto',
  width = '100%',
  maxWidth = 'none',
  maxHeight = 'none',
}: VideoProps) => {
  return (
    <VideoBase
      autoPlay
      muted
      loop
      poster={poster}
      width={width}
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
    >
      {sources.map((src) => (
        <source key={src.type} src={src.src} type={src.type || 'video/webm'} />
      ))}
    </VideoBase>
  );
};

export { Video };
