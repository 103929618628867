import React from 'react';
import { GlobalStyles } from './ui/GlobalStyles';
import { Page } from './ui/components';
import { Banner } from './components/Banner';
import { Clients } from './components/Clients';

const App = (): JSX.Element => {
  return (
    <>
      <GlobalStyles />
      <Page>
        <Banner />
        <Clients />
      </Page>
    </>
  );
};

export { App };
