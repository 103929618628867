import styled from 'styled-components/macro';

const CarouselStyle = styled.div`
  img {
    user-select: none;
    user-drag: none;
  }
`;

export { CarouselStyle };
