import styled from 'styled-components/macro';
import { spaces } from '../../ui/tokens';

type SectionContainerProps = {
  background: string;
  padding: string;
};

const SectionContainer = styled.div<SectionContainerProps>`
  height: auto;
  width: 100%;
  background: ${({ background }) => background};
  padding: ${({ padding }) => padding};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 ${spaces.medium};
`;

export { SectionContainer, SectionWrapper };
