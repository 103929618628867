import styled from 'styled-components/macro';

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

export { PageWrapper };
