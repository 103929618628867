const screens = {
  xl: '1600px',
  lgEx: '1260px',
  lg: '1200px',
  md: '980px',
  sm: '768px',
  xs: '320px',
  xlMin: '1601px',
  lgExMin: '1261px',
  lgMin: '1201px',
  mdMin: '981px',
  smMin: '769px',
  xsMin: '321px',
} as const;

export { screens };
