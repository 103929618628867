import { HTMLAttributes } from 'react';
import styled from 'styled-components';

type VideBaseProps = HTMLAttributes<HTMLVideoElement> & {
  maxWidth: string;
  maxHeight: string;
};

const VideoBase = styled.video<VideBaseProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
`;

export { VideoBase };
