import React from 'react';
import {
  Box,
  Button,
  Typography,
  GridContainer,
  GridItem,
  Video,
  Center,
} from '../../ui/components';
import { colors } from '../../ui/tokens';
import { Section } from '../Section';
import {
  TempBannerWrapper,
  ButtonsRow,
  BannerLink,
  Picture,
} from './Banner.styles';

const Banner = () => {
  return (
    <TempBannerWrapper>
      <Center>
        <Section>
          <GridContainer columns={12} noPadding>
            <GridItem lg={6} sm={12}>
              <Box
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Picture>
                  <img
                    src="/images/komutilo-logo.svg"
                    style={{ maxWidth: '152px' }}
                  />
                </Picture>
                <Typography variant="title" margin={`32px 0`}>
                  Creating bonds between persons through technology
                </Typography>
                <Typography
                  variant="paragraph1"
                  color={colors.gray_200}
                  margin={`0 0 32px`}
                >
                  We use what is more modern and efficient to develop our
                  software united with a search for the better result, always
                  aiming at each customer&apos;s business reality and their
                  consumers.
                </Typography>
                <ButtonsRow>
                  <BannerLink
                    href="mailto:contact@komutilo.com"
                    target="_blank"
                  >
                    <Button variant="solid">Contact us</Button>
                  </BannerLink>
                  <BannerLink
                    href="https://www.linkedin.com/company/komutilo/jobs"
                    target="_blank"
                  >
                    <Button variant="default">Carrers</Button>
                  </BannerLink>
                </ButtonsRow>
              </Box>
            </GridItem>
            <GridItem lg={6} sm={12}>
              <Box
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Video
                  poster="/images/komutilo-team-illustration-banner.svg"
                  sources={[
                    {
                      src: '/videos/komutilo-team-animation-banner.webm',
                      type: 'video/webm',
                    },
                    {
                      src: '/videos/komutilo-team-animation-banner.mp4',
                      type: 'video/mp4',
                    },
                  ]}
                  maxHeight="430px"
                />
              </Box>
            </GridItem>
          </GridContainer>
        </Section>
      </Center>
    </TempBannerWrapper>
  );
};

export { Banner };
