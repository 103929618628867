import React, { ReactNode } from 'react';
import ReactMultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ResponsiveType } from 'react-multi-carousel';
import { CarouselStyle } from './Carousel.styles';

type CarouselProps = {
  children: ReactNode;
  responsive: ResponsiveType;
};

const Carousel = ({ children, ...rest }: CarouselProps) => {
  return (
    <CarouselStyle>
      <ReactMultiCarousel
        showDots={false}
        arrows={false}
        infinite={true}
        autoPlay={true}
        transitionDuration={2}
        swipeable={true}
        draggable={true}
        centerMode={true}
        {...rest}
      >
        {children}
      </ReactMultiCarousel>
    </CarouselStyle>
  );
};

export { Carousel };
