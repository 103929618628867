import styled from 'styled-components/macro';
import { spaces, colors, zIndex } from '../../tokens';
import { Typography } from '../Typography';
import { Button } from '../Button';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ListOptions = styled.div<{ align: string }>`
  position: absolute;
  z-index: ${zIndex.purgatory};
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.06);
  top: calc(100% + ${spaces.xSmall});
  min-width: 200px;
  width: max-content;
  background: ${colors.white};
  border-radius: 8px;
  padding-top: ${spaces.none} ${spaces.xSmall} ${spaces.xSmall};
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${({ align }) => {
    switch (align) {
      case 'left':
        return 'left: 0';
      case 'right':
        return 'right: 0';
      default:
        return 'left: 0';
    }
  }}
`;

const Option = styled.div`
  position: relative;
  background-color: ${colors.white};
  height: 56px;
  display: flex;
  align-items: center;
  outline: none;
  background: ${colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${colors.white};
  }

  &:active {
    background-color: ${colors.white};
  }

  & > * {
    padding-left: ${spaces.medium};
    color: ${colors.blue_600};
    cursor: pointer;
  }

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    right: 0;
    top: 0;
  }
`;

const Title = styled(Typography).attrs({
  variant: 'button',
})`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: ${spaces.medium};
`;

export { Wrapper, Button, ListOptions, Option, Title };
